<template>
  <div class="d-flex flex-column">
    <span class="font-weight-bold">Languages</span>
    <a-select
      allow-clear
      :model-value="value"
      placeholder="Select language"
      size="large"
      style="width: 220px;"
      @change="handleChange"
      :disabled="disabled"
    >
      <a-select-option
        v-for="localization in localizations"
        :key="localization.value"
      >
        {{ localization.label }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>

export default {
  name: 'localizationsSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    localizations: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
