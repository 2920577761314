<template>
    <div class="d-flex flex-column">
      <span v-if="!hideTitle" class="font-weight-bold">{{ title }}</span>
      <a-select
        :allow-clear="allowClear"
        :value="value"
        placeholder="Select type"
        size="large"
        @change="handleChange"
        :disabled="disabled"
      >
        <a-select-option
          v-for="type in types"
          :key="type.value"
        >
          {{ type.label }}
        </a-select-option>
      </a-select>
    </div>
</template>

<script>
export default {
  name: 'typeSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: 'Type',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      types: [
        {
          label: 'Image',
          value: 'image',
        },
        {
          label: 'Splashscreen',
          value: 'splashscreen',
        },
      ],
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
