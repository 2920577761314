<template>
  <div>
    <a-row class="align-items-center mb-4" style="margin: -30px; padding: 15px 30px 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="mb-0" style="font-size: 20px;">Stories</h3>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
        <a-button v-if="permissionCreate" type="primary" class="ml-4" size="large" @click="openCreateStoryModal">
          <a-icon type="plus"/>
          Create new
        </a-button>
      </a-col>
    </a-row>
    <a-card>
      <div class="d-flex align-items-center mb-4">
        <AppSelect v-model="filters.applicationId" :apps="apps" class="mr-3" @change="tableUpdate"
                   :disabled="appsLoading"
                   style="width: 220px;"/>
        <LocalizationsSelect v-model="filters.localizationId" :localizations="languagesOptions"
                             :disabled="languagesLoading"
                             class="mr-3" @change="tableUpdate"/>
        <TypeSelect v-model="filters.type" class="mr-3" @change="tableUpdate" style="width: 220px;"/>
        <ActiveStatus v-model="filters.active" @change="tableUpdate"/>
      </div>
      <a-table
        :columns="columns"
        :data-source="stories"
        :pagination="pagination"
        :loading="tableLoading"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :customRow="customRow"
      >
        <template #image="text, record">
          <a-avatar
            :src="getStoryPreview(record)"
            shape="square"
            size="large"
            style="width: 40px; height: 40px;"
          />
        </template>
        <template #dates="text, record">
            <span>
              {{ formatDate(record.start) }} - {{ formatDate(record.end) }}
            </span>
        </template>
        <template #languages="text, record">
            <span class="text-uppercase">
              {{ getLanguages(record) }}
            </span>
        </template>
        <template #type="text, record">
            <span class="text-capitalize">
              {{ record.type }}
            </span>
        </template>
        <template #app="text, record">
          <a-tag style="margin-right: 15px; padding: 1px 8px; border-radius: 2px;"
                 v-for="app in record.applications.slice(0, 5)" :key="app.id">
            {{ app.name }}
            <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
            <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
            <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
          </a-tag>
          <a-tag
            v-if="record.applications.length > 4"
          >
            ...
          </a-tag>
        </template>
        <template #active="text, record">
          <a-switch
            id="activeStatusToggle"
            class="ml-4"
            :default-checked="record.is_active"
            :disabled="!permissionEdit"
            @change="toggleStatus($event, record.id)"
          />
        </template>
      </a-table>
      <a-modal
        v-model="addModal"
        title="New story"
        :footer="null"
        width="800px"
        :destroyOnClose="true"
      >
        <StoryForm :apps="apps" :languages="languages" @close="closeCreateStoryModal" @save="handleCreateStoryModal"/>
      </a-modal>
      <a-modal
        v-model="editModal"
        title="Edit story"
        :footer="null"
        width="800px"
        :destroyOnClose="true"
      >
        <StoryForm :story="selectedStoryToEdit"
                   :apps="apps"
                   :languages="languages"
                   @close="closeEditStoryModal"
                   @save="handleEditStoryModal"/>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix'
import AppSelect from '@/views/apps/stories/filters/appSelect.vue'
import LocalizationsSelect from '@/views/apps/stories/filters/localizationsSelect.vue'
import TypeSelect from '@/views/apps/stories/filters/typeSelect.vue'
import ActiveStatus from '@/views/apps/stories/filters/activeStatus.vue'
import StoryForm from '@/views/apps/stories/form/storyForm.vue'

export default {
  name: 'Stories',
  components: { StoryForm, ActiveStatus, TypeSelect, LocalizationsSelect, AppSelect },
  mixins: [accessMix],
  data() {
    return {
      tableLoading: false,
      appsLoading: false,
      languagesLoading: false,
      stories: [],
      apps: [],
      languages: [],
      pagination: {
        pageSize: 8,
        current: 1,
        total: 0,
      },
      filters: {
        applicationId: undefined,
        localizationId: undefined,
        type: undefined,
        active: true,
      },
      selectedStoryToEdit: null,
      addModal: false,
      editModal: false,
      columns: [
        {
          title: 'Image',
          scopedSlots: { customRender: 'image' },
        },
        {
          title: 'Dates',
          scopedSlots: { customRender: 'dates' },
        },
        {
          title: 'Languages',
          scopedSlots: { customRender: 'languages' },
        },
        {
          title: 'Type',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: 'App',
          scopedSlots: { customRender: 'app' },
        },
        {
          title: 'Active',
          scopedSlots: { customRender: 'active' },
        },
      ],
    }
  },
  async created() {
    await this.tableUpdate()
    await this.fetchApplications()
    await this.fetchLocalizations()
  },
  computed: {
    permissionCreate() {
      return this.checkPermissions(this.user.permissions, 'stories', 'create')
    },
    permissionEdit() {
      return this.checkPermissions(this.user.permissions, 'stories', 'edit')
    },
    languagesOptions() {
      return this.languages.map(function (row) {
        return { value: row.id, label: row.desc }
      })
    },
  },
  methods: {
    openCreateStoryModal() {
      this.addModal = true
    },
    closeCreateStoryModal() {
      this.addModal = false
    },
    async handleCreateStoryModal() {
      this.closeCreateStoryModal()
      await this.tableUpdate()
    },
    openEditModal(story) {
      this.selectedStoryToEdit = story
      this.editModal = true
    },
    closeEditStoryModal() {
      this.selectedStoryToEdit = null
      this.editModal = false
    },
    handleEditStoryModal() {
      this.closeEditStoryModal()
      this.tableUpdate()
    },
    formatDate: function (date) {
      if (!date) {
        return '∞'
      }
      return this.$moment(date).format('DD.MM.YY HH:mm')
    },
    getLanguages(story) {
      return Object.keys(story.images).join(', ')
    },
    getStoryPreview(story) {
      const firstLang = Object.keys(story.preview)[0]
      const firstLangPreview = story.preview[firstLang][0]
      return firstLangPreview.path
    },
    tableChange(e) {
      this.pagination = e
      this.tableUpdate()
    },
    async tableUpdate() {
      try {
        this.tableLoading = true
        await this.fetchStories()
      } catch (e) {
        console.error(e)
      } finally {
        this.tableLoading = false
      }
    },
    async fetchStories() {
      const { current } = this.pagination
      const { active, type, localizationId, applicationId } = this.filters
      const searchParams = new URLSearchParams()
      searchParams.append('page', current)
      searchParams.append('active', active)

      if (type) {
        searchParams.append('type', type)
      }

      if (localizationId) {
        searchParams.append('lang_id', localizationId)
      }

      if (applicationId) {
        searchParams.append('app_id', applicationId)
      }

      const { data } = await apiClient.get(`admin/stories?${searchParams}`)
      this.stories = data.data
      this.pagination.total = data.meta.total
    },
    async fetchApplications() {
      this.appsLoading = true
      try {
        const url = '/admin/applications'
        const response = await apiClient.get(url)
        if (response.data.data) {
          this.apps = response.data.data
        }
      } finally {
        this.appsLoading = false
      }
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async toggleStatus(checked, storyId) {
      try {
        await apiClient.patch(`admin/stories/${storyId}`, {
          is_active: checked,
        })
        await this.tableUpdate()
        this.$notification.success({
          message: 'Active status changed',
          description: `The story with the id ${storyId} has been successfully changed`,
        })
      } catch (e) {
        console.error(e)
      }
    },
    customRow(story) {
      const _this = this

      return {
        on: {
          click: (e) => {
            if (e.target.closest('#activeStatusToggle')) {
              return
            }
            _this.openEditModal(story)
          },
        },
      }
    },
  },
}
</script>
