<template>
  <div>
    <div class="d-flex flex-column">
      <div class="d-flex flex-row align-items-start justify-content-between mb-2">
        <span class="font-weight-bold font-size-16">General</span>
        <ActiveStatus v-model="active" hide-title reverse switch-title="Active" :disabled="loading"/>
      </div>
      <div class="d-flex flex-row align-items-start" style="gap:1rem;">
        <a-form-model-item label="Story type" prop="type" class="d-inline-block pr-1 flex-grow-1">
          <TypeSelect v-model="type" title="Story type" hide-title :allowClear="false" :disabled="loading"/>
        </a-form-model-item>
        <a-form-model-item label="Start date" prop="startDate" class="d-inline-block pr-1">
          <a-date-picker
            v-model="startDate"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            type="date"
            size="large"
            placeholder="Select date"
            :disabled="loading"
          />
          <div v-if="errors.startDate" style="color: red;">Start date is required</div>
        </a-form-model-item>
        <a-form-model-item label="End date" prop="endDate" class="d-inline-block pr-1">
          <a-date-picker
            v-model="endDate"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            type="date"
            size="large"
            placeholder="Select date"
            :disabled="loading"
          />
          <div v-if="errors.endDate" style="color: red;">Start date is required</div>
        </a-form-model-item>
      </div>
      <div>
        <a-form-model-item label="Apps" prop="applications" required class="d-block">
          <a
            @click.stop="handleAddApp"
            style="color: rgb(0, 123, 255) !important; position: relative; left: 58px; top: -37px;white-space: nowrap; display: block; height: 0">
            + Add
          </a>
          <a-row v-for="(selectedApp, selectedAppIndex) in selectedApps" :key="selectedAppIndex" class="mb-2 pl-0">
            <a-col :span="12" class="pr-1">
              <AppSelect v-model="selectedApp.applicationId" :apps="apps" hide-title :disabled="loading"/>
            </a-col>
            <template v-if="selectedApp.showVersions">
              <a-col :span="4" class="px-1">
                <a-input-group compact class="d-block">
                  <a-tooltip class="d-block" :trigger="['focus']" style="margin: auto" title="Min version (e.g., 4.41)"
                             placement="topLeft" overlay-class-name="numeric-input">
                    <a-input
                      style="font-size: 14px !important;"
                      v-model="selectedApp.minAppVersion"
                      placeholder="Min Version"
                      size="large"
                      :disabled="loading"
                      class="app-version-input"/>
                  </a-tooltip>
                </a-input-group>
              </a-col>
              <a-col :span="4" class="px-1">
                <a-input-group compact class="d-block">
                  <a-tooltip class="d-block" :trigger="['focus']" style="margin: auto" title="Max version (e.g., 4.51)"
                             placement="topLeft" overlay-class-name="numeric-input">
                    <a-input
                      style="font-size: 14px !important;"
                      placeholder="Max Version"
                      v-model="selectedApp.maxAppVersion"
                      size="large"
                      class="app-version-input"
                      :disabled="loading"
                    />
                  </a-tooltip>
                </a-input-group>
              </a-col>
            </template>
            <template v-else>
              <a-col :span="4" class="px-1">
                <a v-if="!loading" @click.stop="handleAddVersion(selectedAppIndex)" style="color: rgb(0, 123, 255);">
                  + Add version
                </a>
              </a-col>
            </template>

            <a-col :span="1" class="text-center">
              <a-icon type="delete" @click="handleDeleteApp(selectedAppIndex)"
                      style="color:red; cursor: pointer; margin: auto; font-size: 16px;"/>
            </a-col>
          </a-row>
          <div v-if="errors.apps" style="color: red;">Please select at least one application</div>
          <div v-if="errors.allAppsAreDefined" style="color: red;">All apps should be defined</div>
        </a-form-model-item>
      </div>
      <a-divider class="m-0 mb-3"/>
      <div>
        <div class="d-flex flex-row align-items-center justify-content-between mb-2">
          <span class="font-weight-bold font-size-16">Images</span>
          <div class="checkbox-container">
            <a-button type="link" :disabled="!isApllyToAllAvailable || loading" @click="addToAllLangs">
              Apply to all languages
            </a-button>
            <!-- <a-checkbox :disabled="!isApllyToAllAvailable || loading" @change="addToAllLangsBox">
              Apply to all languages
            </a-checkbox> -->
          </div>
        </div>
        <a-tabs :default-active-key="languages[0].name" :active-key="selectedLanguage" @change="handleTabChange" :tabBarStyle="{ border: 'none' }">
          <a-tab-pane v-for="language in languages" :key="language.name" :tab="language.desc"/>
        </a-tabs>
        <div class="images">
          <div class="d-flex flex-column">
            <span class="mb-2 title">Preview</span>
            <div class="preview-container">
              <div>
                <a-upload
                  list-type="picture-card"
                  class="uploader preview"
                  :show-upload-list="false"
                  @change="handlePreviewUpload"
                  :disabled="loading"
                >
                  <div>
                    <div v-if="selectedPreview.url" class="preview-image">
                      <img :src="selectedPreview.url"/>
                    </div>
                    <template v-else>
                      <a-icon :type="selectedPreview.loading ? 'loading' : 'plus'"/>
                      <div class="text">
                        File size<br/>
                        500 x 500 px
                      </div>
                    </template>
                  </div>
                </a-upload>
              </div>
            </div>
          </div>
          <div v-if="type === 'image'" class="d-flex flex-column">
            <span class="mb-2 title">Stories</span>
            <div class="d-flex flex-row" style="gap: 0.5rem;">
              <div>
                <a-upload
                  list-type="picture-card"
                  class="uploader stories"
                  :show-upload-list="false"
                  @change="handleStoryUpload"
                  :disabled="loading"
                >
                  <a-icon type="plus" class="icon"/>
                  <div class="text">
                    File size<br/>
                    1400 x 2200px
                  </div>
                </a-upload>
              </div>
              <div class="story-list" :class="{'disabled': loading}" ref="storyList">
                <div v-for="(story, index) in selectedStories" :key="story.id" class="story-item">
                  <template v-if="story.preview">
                      <a-upload list-type="picture-card" class="uploader stories" :show-upload-list="false" @change="handleStoryUpload" :openFileDialogOnClick="isModalOpenOnClick" >
                        <img :src="story.preview" />
                        <div v-if="!loading" class="overlay">
                          <div class="edit-story" @click="editStory(index)">
                            <a-icon type="edit" class="text-white"/>
                          </div>
                          <div class="delete-story" @click="deleteStory(index)">
                            <a-icon type="delete" class="text-white"/>
                          </div>
                        </div>
                      </a-upload>
                  </template>
                  <template v-else>
                    <div class="loader">
                      <a-icon type="loading"/>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="type === 'splashscreen'" class="mt-2">
          <a-form-model-item label="Splashscreen links" prop="links" required class="d-block">
            <a
              v-if="!loading"
              @click.stop="handleAddLink"
              style="color: rgb(0, 123, 255) !important; position: relative; left: 160px; top: -37px;white-space: nowrap; display: block; height: 0">
              + Add
            </a>
            <a-row v-for="(link, linkIndex) in selectedLinks" :key="linkIndex" class="mb-2 pl-0">
              <a-col :span="15" class="pr-1">
                <a-input v-model="selectedLinks[linkIndex]" placeholder="Link" size="large" :disabled="loading"/>
              </a-col>
              <a-col :span="1" class="text-center">
                <a-icon type="delete" @click="handleDeleteLink(linkIndex)"
                        style="color:red; cursor: pointer; margin: auto; font-size: 16px;"/>
              </a-col>
            </a-row>
          </a-form-model-item>
          <div v-if="errors.links" style="color: red;">Please add at least one link</div>
        </div>
      </div>
      <hr style="margin-left: -24px; margin-right: -24px;"/>
      <a-row style="margin-top:0 !important;">
        <a-col v-if="isEditMode" :span="12">
          <a-popconfirm
            title="Sure to delete?"
            @confirm.stop="preformDeleteStory"
          >
            <a-button type="danger">
              Delete
            </a-button>
          </a-popconfirm>
        </a-col>
        <a-col class="text-right" :span="isEditMode ? 12 : 24">
          <CustomButton style="margin-right: 1rem;" @click="close" :disabled="loading">
            Cancel
          </CustomButton>
          <a-button v-if="!isEditMode" type="primary" @click="save" :disabled="loading">
            Add
          </a-button>
          <a-button v-else type="primary" @click="update" :disabled="loading">
            Save
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import ActiveStatus from '@/views/apps/stories/filters/activeStatus.vue'
import TypeSelect from '@/views/apps/stories/filters/typeSelect.vue'
import AppSelect from '@/views/apps/stories/filters/appSelect.vue'
import CustomButton from '@/components/custom/button/CustomButton.vue'
import apiClient from '@/services/axios'

const defaultApp = {
  applicationId: undefined,
  minAppVersion: undefined,
  maxAppVersion: undefined,
  showVersions: false,
}

const defaultPreview = {
  id: null,
  url: '',
  file: null,
  loading: false,
}

const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

function getBase64(img) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(img)
  })
}

function getImageDimensions(file) {
  return new Promise((resolve) => {
    const img = document.createElement('img')
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      })
    }
  })
}

function getVideoDimensions(file) {
  return new Promise((resolve) => {
    const video = document.createElement('video')
    video.src = URL.createObjectURL(file)
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }
  })
}

const generateVideoPreview = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')

    video.autoplay = true
    video.muted = true
    video.src = URL.createObjectURL(file)

    video.onloadeddata = () => {
      const ctx = canvas.getContext('2d')

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      video.pause()
      return resolve(canvas.toDataURL('image/png'))
    }
  })
}

export default {
  name: 'storyForm',
  components: { CustomButton, AppSelect, TypeSelect, ActiveStatus },
  props: {
    apps: {
      type: Array,
      default: () => [],
    },
    languages: {
      type: Array,
      default: () => [],
    },
    story: {
      type: Object,
      optional: true,
      default: () => null,
    },
  },
  data() {
    return {
      active: false,
      type: 'image',
      selectedApps: [
        { ...defaultApp },
      ],
      selectedLanguage: 'ru',
      content: {
        ru: {
          preview: {
            ...defaultPreview,
          },
          links: [''],
          stories: [],
        },
      },
      startDate: null,
      endDate: null,
      loading: false,
      errors: {
        startDate: false,
        endDate: false,
        apps: false,
      },
      currentCoverIndex: '',
      isEditingCover: false,
      isModalOpenOnClick: false,
    }
  },
  computed: {
    isEditMode() {
      return !!this.story
    },
    selectedContent() {
      return this.content[this.selectedLanguage]
    },
    selectedStories() {
      return this.selectedContent.stories
    },
    selectedPreview() {
      return this.selectedContent.preview
    },
    selectedLinks() {
      return this.selectedContent.links
    },
    selectedLanguageId() {
      return this.languages.find((language) => {
        return language.name === this.selectedLanguage
      }).id
    },
    isApllyToAllAvailable() {
      if (this.checkIsObjectEmpty(this.selectedContent)) {
        return false
      }

      const nonSelectedLanguages = Object.values(this.content)
        .filter((language) => language !== this.selectedLanguage)

      const emptyLanguagesCount = nonSelectedLanguages.filter(currentLang => this.checkIsObjectEmpty(currentLang)).length

      return emptyLanguagesCount === (this.languages.length - 1)
    },
  },
  created() {
    if (!this.story) {
      this.selectedLanguage = this.languages[0].name
      this.content = this.languages.reduce((acc, language) => {
        return {
          ...acc,
          [language.name]: {
            preview: { ...defaultPreview },
            links: [''],
            stories: [],
          },
        }
      }, {})
      return
    }

    this.active = this.story.is_active
    this.type = this.story.type
    this.startDate = this.story.start
    this.endDate = this.story.end
    this.selectedApps = this.story.applications.map((app) => {
      const isDefaultMinVersion = app.min_app_version === '0.0.0'
      const isDefaultMaxVersion = app.max_app_version === '999.99.99'
      const isVersionsProvided = app.min_app_version || app.max_app_version
      const showVersions = isVersionsProvided && (!isDefaultMinVersion || !isDefaultMaxVersion)
      return {
        applicationId: app.application_id,
        minAppVersion: !isDefaultMinVersion ? app.min_app_version : undefined,
        maxAppVersion: !isDefaultMaxVersion ? app.max_app_version : undefined,
        showVersions,
      }
    })

    const storyLanguages = Object.keys(this.story.preview)
    this.selectedLanguage = storyLanguages[0]
    const isSplashscreenType = this.story.type === 'splashscreen'
    const storyContent = storyLanguages.reduce((acc, language) => {
      const storyContentData = {}
      if (isSplashscreenType) {
        storyContentData['stories'] = []
        storyContentData['links'] = this.story.images[language].map((image) => {
          return image.path
        })
      }

      if (!isSplashscreenType) {
        storyContentData['stories'] = this.story.images[language].map((image) => {
          return {
            id: image.id,
            preview: image.path,
            loading: false,
          }
        })
        storyContentData['links'] = ['']
      }

      return {
        ...acc,
        [language]: {
          preview: {
            ...defaultPreview,
            id: this.story.preview[language][0].id,
            url: this.story.preview[language][0].path,
          },
          ...storyContentData,
        },
      }
    }, {})

    const defaultContent = this.languages.reduce((acc, language) => {
      return {
        ...acc,
        [language.name]: {
          preview: { ...defaultPreview },
          links: [''],
          stories: [],
        },
      }
    }, {})

    this.content = {
      ...defaultContent,
      ...storyContent,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    validateForm() {
      const errors = {
        startDate: !this.startDate,
        endDate: !this.endDate,
        apps: !this.selectedApps.length,
        allAppsAreDefined: !this.selectedApps.every((app) => {
          return !!app.applicationId
        }),
        links: this.type === 'splashscreen' && (!this.selectedContent.links.length),
      }
      this.errors = { ...errors }
      const errorResults = Object.values(errors)
      return !errorResults.includes(true)
    },
    async save() {
      this.loading = true
      const isFormValid = this.validateForm()

      if (!isFormValid) {
        this.loading = false
        return
      }
      await this.createStory()
    },
    async update() {
      this.loading = true
      const isFormValid = this.validateForm()

      if (!isFormValid) {
        this.loading = false
        return
      }
      await this.updateStory()
    },
    async preformDeleteStory() {
      try {
        const deleteResponse = await apiClient.delete(`/admin/stories/${this.story.id}`)
        if (deleteResponse.data.error) {
          this.$message.error(deleteResponse.data.error.message)
          return
        }

        this.$message.success('Story deleted successfully')
        this.$emit('save')
      } catch (e) {
        this.$message.error(e.response.data.error.message)
      }
    },
    getStoryIdsByLanguage() {
      return Object.entries(this.content).reduce((acc, [language, content]) => {
        return {
          ...acc,
          [language]: content.stories.map((story) => story.id),
        }
      }, {})
    },
    getPreviewsByLanguage() {
      return Object.entries(this.content).reduce((acc, [language, content]) => {
        return {
          ...acc,
          [language]: content.preview.id ? [content.preview.id] : [],
        }
      }, {})
    },
    getLinksByLanguage() {
      return Object.entries(this.content).reduce((acc, [language, content]) => {
        const links = content.links.filter((link) => !!link)
        return {
          ...acc,
          [language]: links,
        }
      }, {})
    },
    getApplications() {
      return this.selectedApps.map((app) => {
        return {
          application_id: app.applicationId,
          min_app_version: app.minAppVersion,
          max_app_version: app.maxAppVersion,
        }
      })
    },
    getStoryPayload() {
      return {
        type: this.type,
        is_active: this.active,
        start: this.$moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
        end: this.$moment(this.endDate).format('YYYY-MM-DD HH:mm'),
        applications: this.getApplications(),
        covers: this.getStoryIdsByLanguage(),
        preview: this.getPreviewsByLanguage(),
        links: this.getLinksByLanguage(),
      }
    },
    async createStory() {
      try {
        this.loading = true

        const storyPayload = this.getStoryPayload()
        const storyResponse = await apiClient.post('/admin/stories', storyPayload)

        if (storyResponse.data.error) {
          this.loading = false
          this.$message.error(storyResponse.data.error.message)
          return
        }
        this.$emit('save')
      } catch (e) {
        this.$message.error(e.response.data.error.message)
        this.loading = false
      }
    },
    async updateStory() {
      try {
        this.loading = true
        const storyPayload = this.getStoryPayload()
        const deleteResponse = await apiClient.patch(`/admin/stories/${this.story.id}`, storyPayload)
        if (deleteResponse.data.error) {
          this.$message.error(deleteResponse.data.error.message)
          this.loading = false
          return
        }

        this.$message.success('Story updated successfully')
        this.$emit('save')
      } catch (e) {
        this.$message.error(e.response.data.error.message)
        this.loading = false
      }
    },
    handleAddApp() {
      if (this.loading) {
        return
      }
      this.selectedApps.push({ ...defaultApp })
    },
    handleDeleteApp(index) {
      if (this.loading) {
        return
      }

      this.selectedApps.splice(index, 1)
    },
    handleAddVersion(index) {
      if (this.loading) {
        return
      }

      this.selectedApps[index].showVersions = true
    },
    handleTabChange(selectedLangKey) {
      if (this.loading) {
        return
      }
      this.selectedLanguage = selectedLangKey
    },
    async validateUploadedStory(file) {
      const fileType = file.type.split('/')[0]
      const isImage = fileType === 'image'
      const isVideo = fileType === 'video'

      if (!isImage && !isVideo) {
        this.$message.error('You can only upload JPG/GIF/PNG/MP4 file!')
        return false
      }

      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isLt5M) {
        this.$message.error('Story must be smaller than 5MB!')
        return false
      }

      if (isVideo) {
        const { width, height } = await getVideoDimensions(file)
        if (width !== 1400 || height !== 2200) {
          this.$message.error('Video must be 1400px x 2200px!')
          return false
        }
      }

      if (isImage) {
        const { width, height } = await getImageDimensions(file)
        if (width !== 1400 || height !== 2200) {
          this.$message.error('Image must be 1400px x 2200px!')
          return false
        }
      }

      return file
    },
    async handleStoryUpload(event) {
      if (event.file.status === 'uploading') {
        return
      }

      const file = event.file

      const isFileValid = await this.validateUploadedStory(event.file.originFileObj)

      if (!isFileValid) {
        return
      }

      const fileType = file.type.split('/')[0]
      const fileId = file.uid

      const story = {
        id: fileId,
        preview: '',
        loading: true,
        file,
      }

      this.selectedContent.stories.unshift(story)

      const formData = new FormData()

      formData.append('cover', file.originFileObj)
      formData.append('lang_id', this.selectedLanguageId)

      try {
        const response = await apiClient.post('/admin/stories/cover', formData)
        const storyId = response.data?.data?.id
        if (!storyId) {
          const errorMessage = response.data?.error?.message || 'Something went wrong while uploading the story'
          this.selectedContent.stories.splice(0, 1)
          this.$message.error(errorMessage)
          return
        }

        if (fileType === 'image') {
          story.id = storyId
          story.preview = await getBase64(event.file.originFileObj)
          story.loading = false
        }

        if (fileType === 'video') {
          story.id = storyId
          story.preview = await generateVideoPreview(event.file.originFileObj)
          story.loading = false
        }
      } catch (e) {
        const errorMessage = e.response.data?.error?.message || 'Something went wrong while uploading the story'
        this.selectedContent.stories.splice(0, 1)
        this.$message.error(errorMessage)
      }
      if (this.isEditingCover) {
        this.deleteStory(this.currentCoverIndex + 1)
        this.isModalOpenOnClick = false
        this.isEditingCover = false
      }
    },
    deleteStory(index) {
      this.selectedContent.stories.splice(index, 1)
    },
    editStory(index) {
      this.isModalOpenOnClick = true
      this.isEditingCover = true
      this.currentCoverIndex = index
    },
    async validateUploadedPreview(file) {
      const isImage = file.type.split('/')[0] === 'image'

      if (!isImage) {
        this.$message.error('You can only upload JPG/GIF/PNG file!')
        return false
      }

      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isLt5M) {
        this.$message.error('Preview must be smaller than 5MB!')
        return false
      }

      if (isImage) {
        const { width, height } = await getImageDimensions(file)
        if (width !== 500 || height !== 500) {
          this.$message.error('Preview must be 500px x 500px!')
          return false
        }
      }

      return file
    },
    async handlePreviewUpload(event) {
      if (event.file.status === 'uploading') {
        return
      }

      const file = event.file

      const isFileValid = await this.validateUploadedPreview(event.file.originFileObj)

      if (!isFileValid) {
        return
      }

      const fileId = file.uid

      this.content[this.selectedLanguage].preview = {
        ...this.selectedPreview,
        loading: true,
        id: fileId,
        file,
      }

      const formData = new FormData()

      formData.append('preview', file.originFileObj)

      try {
        const response = await apiClient.post('/admin/stories/preview', formData)
        const previewId = response.data?.data?.id
        if (!previewId) {
          const errorMessage = response.data?.error?.message || 'Something went wrong while uploading the preview'
          this.content[this.selectedLanguage].preview = {
            ...defaultPreview,
          }
          this.$message.error(errorMessage)
        }
        this.content[this.selectedLanguage].preview.id = previewId
        this.content[this.selectedLanguage].preview.url = await getBase64(event.file.originFileObj)
        this.content[this.selectedLanguage].preview.loading = false
      } catch (e) {
        const errorMessage = e.response.data?.error?.message || 'Something went wrong while uploading the preview'
        this.content[this.selectedLanguage].preview = {
          ...defaultPreview,
        }
        this.$message.error(errorMessage)
      }
    },
    handleAddLink() {
      this.selectedContent.links.push('')
    },
    handleDeleteLink(index) {
      this.selectedContent.links.splice(index, 1)
    },
    checkIsObjectEmpty(currentLang) {
      const currentLinksEmpty = currentLang.links.length === 1
      const currentStoriesEmpty = currentLang.stories.length === 0

      return !currentLang.preview.id && currentLinksEmpty && currentStoriesEmpty
    },
    addToAllLangs() {
      for (const language in this.content) {
        const currentLang = this.content[language]
        if (language !== this.selectedLanguage) {
          currentLang.preview = deepClone(this.selectedContent.preview)
          currentLang.links = deepClone(this.selectedContent.links)
          currentLang.stories = deepClone(this.selectedContent.stories)
        }
      }
    },
  },
}
</script>

<style lang="scss">
.images {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }

  .uploader {
    border-radius: 2px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: auto;
    &.stories .ant-upload-list-item,
    &.stories .ant-upload-list-picture-card-container,
    &.stories .ant-upload-select-picture-card {
      width: 120px !important;
      height: 190px !important;
      margin: 0 !important;
      & > span {
        padding: 0;
        & > img {
                width: 120px !important;
                height: 190px !important;
            }
      }
    }
  }
  .story-list {
    width: 450px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;

    & > .story-item {
      position: relative;
      width: 120px;
      height: 190px;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &:hover .overlay {
        opacity: 1;
      }

      .loader {
        width: 120px;
        height: 190px;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .overlay {
        transition: all 0.2s ease-in-out;
        position: absolute;
        display: flex;
        gap: 12px;
        opacity: 0;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        & > div {
          cursor: pointer;
        }
      }

      & > img {
        width: 120px;
        height: 190px;
      }
    }
  }

  .preview-image {
    width: 110px;
    height: 110px;

    & > img {
      width: 110px;
      height: 110px;
    }
  }
}

.checkbox-container {

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-checkbox {
    margin-top: 4px;
  }
}
</style>
