<template>
  <div class="d-flex flex-column">
    <span v-if="!hideTitle" class="font-weight-bold">Application</span>
    <a-select
      :value="value"
      placeholder="Select app"
      size="large"
      :disabled="disabled"
      allow-clear
      @change="handleChange"
    >
      <a-select-option
        v-for="app in apps"
        :key="app.application_id"
      >
        <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="text-success" />
        <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
        <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="text-default" />
        {{ app.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'appSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    apps: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: undefined,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
